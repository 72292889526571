/**
 * The Util service is for thin, globally reusable, utility functions
 */
import { isEqual, isFunction, noop } from 'lodash';

import moment from 'moment-timezone';

function addZero(n) {
  return n < 10 ? `0${n}` : `${n}`;
}

/**
 * Return a callback or noop function
 *
 * @param  {Function|*} cb - a 'potential' function
 * @return {Function}
 */
export function safeCb(cb) {
  return isFunction(cb) ? cb : noop;
}

/**
 * Parse a given url with the use of an anchor element
 *
 * @param  {String} url - the url to parse
 * @return {Object}     - the parsed url, anchor element
 */
export function urlParse(url) {
  var a = document.createElement('a');
  a.href = url;

  // Special treatment for IE, see http://stackoverflow.com/a/13405933 for details
  if(a.host === '') {
    // eslint-disable-next-line no-self-assign
    a.href = a.href;
  }

  return a;
}

/**
 * Test whether or not a given url is same origin
 *
 * @param  {String}           url       - url to test
 * @param  {String|String[]}  [origins] - additional origins to test against
 * @return {Boolean}                    - true if url is same origin
 */
export function isSameOrigin(url, origins) {
  url = urlParse(url);
  origins = origins && [].concat(origins) || [];
  origins = origins.map(urlParse);
  origins.push(window.location);
  origins = origins.filter(function(o) {
    let hostnameCheck = url.hostname === o.hostname;
    let protocolCheck = url.protocol === o.protocol;
    // 2nd part of the special treatment for IE fix (see above):
    // This part is when using well-known ports 80 or 443 with IE,
    // when window.location.port==='' instead of the real port number.
    // Probably the same cause as this IE bug: https://goo.gl/J9hRta
    let portCheck = url.port === o.port || o.port === '' && (url.port === '80' || url.port === '443');
    return hostnameCheck && protocolCheck && portCheck;
  });
  return origins.length >= 1;
}

/**
 * For loading indicator.
 *
 */
var _loading = 0;

export function isLoading() {
  if(_loading <= 0) {
    _loading = 0;
    return false;
  }
  return true;
}

export function startLoading() {
  _loading++;
}
export function stopLoading() {
  _loading--;
}

/**
 * Get "local equivalent" JS date from a moment timezone object.
 *
 */
export function getLocalEquivalentFromMoment(momentDate) {
  if(momentDate) {
    var dateString = momentDate.format('YYYY-MM-DD HH:mm:ss');
    return new Date(dateString);
  }
  return null;
}

/**
 * Get a moment timezone object from a "local equivalent" JS date.
 *
 */
export function getMomentFromLocalEquivalent(localEquivalent, timezone) {
  if(localEquivalent) {
    var dateTime = `${localEquivalent.getFullYear()}-${addZero(localEquivalent.getMonth() + 1)}-${addZero(localEquivalent.getDate())} ${addZero(localEquivalent.getHours())}:${addZero(
      localEquivalent.getMinutes()
    )}:${addZero(localEquivalent.getSeconds())}`;

    return moment.tz(dateTime, timezone);
  }
  return null;
}

/**
 * Get UTC JS date from a moment timezone object.
 *
 */
export function getUtcISOFromMoment(momentDate) {
  if(momentDate) {
    var returnVal = momentDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    momentDate.local();
    return returnVal;
  }
  return null;
}

/**
 * Set the time portion of a date to the current time in the timezone.
 *
 */
export function setToTimezoneTime(dateTime, timezone) {
  if(dateTime) {
    var current = moment().tz(timezone);
    dateTime.setHours(current.hours());
    dateTime.setMinutes(current.minutes());
  }
}

/**
 * Calculate number of days between two dates.
 *
 * @param  {Date/String/moment}    a    - First Date
 * @param  {Date/String/moment}    b    - Second Date
 * @return {int}                        - Number of Days in Between
 */
export function daysBetweenDates(a, b) {
  var first = a;
  var second = b;

  //Accept different input types
  if(typeof a === 'string') {
    first = moment(a).toDate();
  }
  else if(moment.isMoment(a)) {
    first = a.toDate();
  }

  if(typeof b === 'string') {
    second = moment(b).toDate();
  }
  else if(moment.isMoment(b)) {
    second = b.toDate();
  }

  if(!first || !second) return 0;

  //ignore time
  var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
  var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

  var millisecondsPerDay = 1000 * 60 * 60 * 24;
  var millisBetween = two.getTime() - one.getTime();

  //Handle DST
  var tz1 = one.getTimezoneOffset();
  var tz2 = two.getTimezoneOffset();

  if(tz1 !== tz2) {
    var tzd = tz2 - tz1;
    tzd = tzd * 60 * 1000;
    millisBetween -= tzd;
  }

  var days = millisBetween / millisecondsPerDay;

  return Math.floor(days + 1);
}

/**
 * Helper for debugging isChanged()
 *
 */
export function compareObjects(s, t) {
  console.log('start compareObjects...');
  if(typeof s !== typeof t) {
    console.log('two objects not the same type');
    return;
  }
  if(typeof s !== 'object') {
    console.log('arguments are not typeof === "object"');
    return;
  }
  for(var prop in s) {
    if(s.hasOwnProperty(prop)) {
      if(t.hasOwnProperty(prop)) {
        if(!isEqual(s[prop], t[prop])) {
          console.log(`property ${prop} does not match`);
          console.log(s[prop]);
          console.log(t[prop]);
        }
      }
      else {
        console.log(`second object does not have property ${prop}`);
      }
    }
  }
  // now verify that t doesn't have any properties
  // that are missing from s
  for(prop in t) {
    if(t.hasOwnProperty(prop)) {
      if(!s.hasOwnProperty(prop)) {
        console.log(`first object does not have property ${prop}`);
      }
    }
  }
  console.log('done with compareObjects.');
}

/**
 * Helper to check if two objects are equivalent
 *
 */
export function isEquivalentObject(s, t) {
  //console.log('start isEquivalentObject...');
  if(typeof s !== typeof t) {
    //console.log('two objects not the same type');
    return false;
  }
  if(typeof s !== 'object') {
    //console.log('arguments are not typeof === "object"');
    return false;
  }
  for(var prop in s) {
    if(s.hasOwnProperty(prop)) {
      if(t.hasOwnProperty(prop)) {
        if(!isEqual(s[prop], t[prop])) {
          //console.log(`property ${prop} does not match`);
          //console.log(s[prop]);
          //console.log(t[prop]);
          return false;
        }
      }
      else {
        //console.log(`second object does not have property ${prop}`);
        return false;
      }
    }
  }
  // now verify that t doesn't have any properties
  // that are missing from s
  for(prop in t) {
    if(t.hasOwnProperty(prop)) {
      if(!s.hasOwnProperty(prop)) {
        //console.log(`first object does not have property ${prop}`);
        return false;
      }
    }
  }
  //console.log('isEquivalentObject = true.');
  return true;
}

// Timezones
//TODO: Add more timezones, use: moment.tz.names()?
var timezones = ['US/Pacific', 'US/Mountain', 'US/Central', 'US/Eastern', 'America/Sao_Paulo', 'UTC', 'Europe/London', 'Europe/Berlin', 'Asia/Muscat', 'Asia/Kolkata', 'Asia/Shanghai', 'Asia/Tokyo', 'Australia/Sydney', 'Pacific/Auckland'];

export function getTimezones() {
  return timezones;
}

export function guessTimezone() {
  var tz = 'US/Pacific';
  var mTz = moment.tz.guess();

  switch (mTz) {
  case 'America/Denver':
    tz = 'US/Mountain';
    break;

  case 'America/Chicago':
    tz = 'US/Central';
    break;

  case 'America/New_York':
    tz = 'US/Eastern';
    break;
  
  case 'America/Sao_Paulo':
    tz = 'America/Sao_Paulo';
    break;

  case 'Europe/Dublin':
  case 'Europe/London':
    tz = 'Europe/London';
    break;

  case 'Europe/Berlin':
  case 'Europe/Madrid':
  case 'Europe/Paris':
  case 'Europe/Rome':
    tz = 'Europe/Berlin';
    break;
  
  case 'Asia/Muscat':
    tz = 'Asia/Muscat';
    break;

  case 'Asia/Kolkata':
    tz = 'Asia/Kolkata';
    break;

  case 'Asia/Shanghai':
    tz = 'Asia/Shanghai';
    break;

  case 'Asia/Tokyo':
    tz = 'Asia/Tokyo';
    break;

  case 'Australia/Sydney':
    tz = 'Australia/Sydney';
    break;

  case 'Pacific/Auckland':
    tz = 'Pacific/Auckland';
    break;
  }

  return tz;
}

/**
 * Cache functions below.
 *
 */
export function setCacheValue(key, value, ttl) {
  ttl = typeof ttl !== 'undefined' ? ttl : 3600000; //Default ttl == 1 hour == 3600000ms
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl
  };

  try {
    localStorage.setItem(key, JSON.stringify(item));
  }
  catch(e) {
    console.log('Error: setting cache value.');
    console.log(e.toString());
    console.dir(e);
  }
}

export function getCacheValue(key) {
  const itemStr = localStorage.getItem(key);

  if(!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  //check if expired
  if(now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export function removeCacheValue(key) {
  try {
    localStorage.removeItem(key);
  }
  catch(e) {
    console.log('Error: removing cache value.');
    console.log(e.toString());
    console.dir(e);
  }
}

export function clearCache() {
  removeCacheValue('globalFunnel');
  removeCacheValue('globalFunnelFilter');
  removeCacheValue('globalFrequency');
  removeCacheValue('globalFrequencyFilter');
  removeCacheValue('globalWho');
  removeCacheValue('globalWhoLocal');
  removeCacheValue('globalWhoFilter');
  removeCacheValue('globalTimeWindows');
  removeCacheValue('globalTimeWindowsFilter');
}
