// @flow
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { OrganizationService } from '../../organization/shared/organization.service';

import moment from 'moment-timezone';

import { daysBetweenDates, getUtcISOFromMoment, getLocalEquivalentFromMoment, getMomentFromLocalEquivalent, setToTimezoneTime } from '../../../components/util';

@Injectable({
  providedIn: 'root'
})
export class CampaignDatesService {
  _DEFAULT_CAMPAIGN_LENGTH_DAYS = 5 - 1; //default 5 days (subtract 1 include start date)
  _DEFAULT_STO_CAMPAIGN_LENGTH_DAYS = 7; //default 7 days

  static parameters = [DatePipe, HttpClient, OrganizationService];
  constructor(datePipe: DatePipe, http: HttpClient, organizationService: OrganizationService) {
    this.datePipe = datePipe;
    this.http = http;
    this.organizationService = organizationService;
  }

  DEFAULT_CAMPAIGN_LENGTH_DAYS() {
    return this._DEFAULT_CAMPAIGN_LENGTH_DAYS;
  }

  DEFAULT_STO_CAMPAIGN_LENGTH_DAYS() {
    return this._DEFAULT_STO_CAMPAIGN_LENGTH_DAYS;
  }

  timezones() {
    return [
      'US/Pacific', //−08:00, DST: −07:00
      'US/Mountain', //−07:00, DST: −06:00
      'US/Central', //−06:00, DST: −05:00
      'US/Eastern', //−05:00, DST: −04:00
      'America/Mexico_City', //−06:00, DST: −05:00
      'America/Sao_Paulo', //−03:00
      'UTC',
      'Europe/London', //+00:00, DST: +01:00
      'Europe/Berlin', //+01:00, DST: +02:00
      'Asia/Muscat', //+4:00
      'Asia/Kolkata', //+05:30
      'Asia/Shanghai', //+08:00
      'Asia/Tokyo', //+09:00
      'Australia/Sydney', //+10:00, DST: +11:00
      'Pacific/Auckland' //+12:00, +13:00
    ];
  }

  guessTimezone() {
    var tz = 'US/Pacific';
    var mTz = moment.tz.guess();

    switch (mTz) {
    case 'America/Denver':
      tz = 'US/Mountain';
      break;

    case 'America/Chicago':
      tz = 'US/Central';
      break;

    case 'America/New_York':
      tz = 'US/Eastern';
      break;

    case 'America/Mexico_City':
      tz = 'America/Mexico_City';
      break;

    case 'America/Sao_Paulo':
      tz = 'America/Sao_Paulo';
      break;

    case 'Europe/Dublin':
    case 'Europe/London':
      tz = 'Europe/London';
      break;

    case 'Europe/Berlin':
    case 'Europe/Madrid':
    case 'Europe/Paris':
    case 'Europe/Rome':
      tz = 'Europe/Berlin';
      break;
    
    case 'Asia/Muscat':
      tz = 'Asia/Muscat';
      break;

    case 'Asia/Kolkata':
      tz = 'Asia/Kolkata';
      break;

    case 'Asia/Shanghai':
      tz = 'Asia/Shanghai';
      break;

    case 'Asia/Tokyo':
      tz = 'Asia/Tokyo';
      break;

    case 'Australia/Sydney':
      tz = 'Australia/Sydney';
      break;

    case 'Pacific/Auckland':
      tz = 'Pacific/Auckland';
      break;
    }

    return tz;
  }

  setDateOptions(timezone, campaignLocal) {
    var today = campaignLocal.currentDate ? campaignLocal.currentDate : moment();

    //Set date control options
    var tomorrow = moment.tz(today, timezone);
    tomorrow.add(1, 'days');

    var oneYr = moment.tz(today, timezone);
    oneYr.add(1, 'year');

    campaignLocal.endDateOptions = {
      maxDate: oneYr.add(1, 'days').toDate(),
      minDate: tomorrow.toDate()
    };
  }

  initDateOptions(campaign, campaignLocal) {
    var today = campaignLocal.currentDate ? campaignLocal.currentDate : moment();

    //Initialize start date
    campaign.scheduledStart = getUtcISOFromMoment(today);

    //Initialize end date, if not already passed.
    if(!campaign.scheduledEnd || today.isSameOrAfter(campaign.scheduledEnd, 'day')) {
      var endDate = moment.tz(today, campaign.timezone);

      // For STO, set end date as X days from start date
      if(campaign.optimizationCriterion === 'sendTime') {
        endDate.add(this._DEFAULT_STO_CAMPAIGN_LENGTH_DAYS, 'days');
      }
      else {
        endDate.add(this._DEFAULT_CAMPAIGN_LENGTH_DAYS, 'days');
      }

      campaign.scheduledEnd = getUtcISOFromMoment(endDate);
    }

    this.setDateOptions(campaign.timezone, campaignLocal);
  }

  /**
   * Set the dates used for js timezone handling.
   *
   *  We keep 3 copies of each date. For example:
   *
   *  -- campaign.scheduledStart - ISO string in UTC that is stored in the database
   *  -- campaignLocal.startDateTimezone - moment.js object using the correct timezone
   *  -- campaignLocal.scheduledStartLocal - js date showing the "local equivalent" of the date in the campaign's timezone.
   */
  setDates(campaign, campaignLocal) {
    //Check if scheduledStart is in the past. If so, then set to today.
    var today = campaignLocal.currentDate ? campaignLocal.currentDate : moment();
    if(campaign.scheduledStart && today.isAfter(campaign.scheduledStart, 'day') && campaign.status === 'draft') {
      campaign.scheduledStart = getUtcISOFromMoment(today); //Set start date to today.
    }

    //If STO campaign, set end date to 7 days after start date
    if(campaign.optimizationCriterion === 'sendTime' && campaign.scheduledStart) {
      var endDate;

      // If no end date, set to default length
      if(!campaign.scheduledEnd) {
        endDate = moment.tz(campaign.scheduledStart, campaign.timezone);
        endDate.add(this._DEFAULT_STO_CAMPAIGN_LENGTH_DAYS, 'days');
      }
      else {
        endDate = moment.tz(campaign.scheduledEnd, campaign.timezone);
      }

      /* Turn Off This Special Case...
      //Special Case: Bulk STO with send time restrictions.
      if(campaign.isMotionControlled && campaignLocal.restrictSendTimes.active) {
        //Find the last available day and set end date to that.
        // (1) If all days of the week are turned on, continue as usual.
        // (2) If any days are turned off, find day closest to startDate that's turned off.
        // (3) Set endDate to one day before that. (Day with last active send.)
        var foundOff = false;
        var loopDate = endDate.clone();
        var startDate = moment.tz(campaign.scheduledStart, campaign.timezone);

        while(!startDate.isSame(loopDate, 'day')) {
          var dayOfWeek = loopDate.format('dddd').toLowerCase();

          //If we haven't yet found a day turned off, and this day is turned off, set the flag.
          if(!foundOff && !campaignLocal.restrictSendTimes.daysOfTheWeek[dayOfWeek]) {
            foundOff = true;
          }

          //If we already found a day turned off, and the current day is on.
          if(foundOff && campaignLocal.restrictSendTimes.daysOfTheWeek[dayOfWeek]) {
            //Set the endDate to the current loop day.
            endDate = loopDate;
            break;
          }

          loopDate.subtract(1, 'day');
        }
      }
      */

      campaign.scheduledEnd = getUtcISOFromMoment(endDate);
    }

    //Setup a moment date in the campaign's timezone.
    campaignLocal.startDateTimezone = campaign.scheduledStart ? moment.tz(campaign.scheduledStart, campaign.timezone) : null;
    campaignLocal.endDateTimezone = campaign.scheduledEnd ? moment.tz(campaign.scheduledEnd, campaign.timezone) : null;

    //Setup local equivalents to show the dates in the campaign's timezone.
    campaignLocal.scheduledStartLocal = getLocalEquivalentFromMoment(campaignLocal.startDateTimezone);
    campaignLocal.scheduledEndLocal = getLocalEquivalentFromMoment(campaignLocal.endDateTimezone);

    this.updateCampaignLength(campaign, campaignLocal);
  }

  updateCampaignLength(campaign, campaignLocal) {
    if(campaignLocal.startDateTimezone && campaignLocal.endDateTimezone && campaign.isMotionControlled) {
      campaignLocal.currentDay = daysBetweenDates(campaignLocal.startDateTimezone, campaignLocal.currentDate);
      campaignLocal.lengthDays = daysBetweenDates(campaignLocal.startDateTimezone, campaignLocal.endDateTimezone);

      //Count the number of days with actual sends.
      campaignLocal.numDaysWithSends = 0;

      var testDate = new Date(campaignLocal.startDateTimezone);
      var endDate = new Date(campaignLocal.endDateTimezone);
      testDate.setSeconds(0);
      endDate.setSeconds(1);
      testDate = moment(testDate);
      endDate = moment(endDate);

      while(endDate.diff(testDate, 'days', true) >= 0) {
        if(
          campaignLocal.restrictSendTimes.daysOfTheWeek.hasOwnProperty(testDate.format('dddd').toLowerCase())
          && campaignLocal.restrictSendTimes.daysOfTheWeek[testDate.format('dddd').toLowerCase()]
        ) {
          campaignLocal.numDaysWithSends++;
        }

        testDate.add(1, 'day');
      }
    }
    else {
      campaignLocal.lengthDays = null;
    }
  }

  //Make sure scheduledStart and scheduledEnd have the same hour and minutes,
  // regardless of Daylight Savings Time (DST).
  alignDates(campaign, campaignLocal) {
    if(campaignLocal.startDateTimezone && campaignLocal.endDateTimezone) {
      campaignLocal.startDateTimezone.utc();
      campaignLocal.endDateTimezone.utc();

      //If Bulk STO with send time restrictions, don't align hours.
      if(campaign.optimizationCriterion !== 'sendTime' || !campaign.isMotionControlled || !campaignLocal.restrictSendTimes.active) {
        if(campaignLocal.startDateTimezone.hours() > campaignLocal.endDateTimezone.hours()) {
          campaignLocal.endDateTimezone.hours(campaignLocal.startDateTimezone.hours());
          campaign.scheduledEnd = getUtcISOFromMoment(campaignLocal.endDateTimezone);
        }

        if(campaignLocal.startDateTimezone.hours() < campaignLocal.endDateTimezone.hours()) {
          campaignLocal.startDateTimezone.hours(campaignLocal.endDateTimezone.hours());
          campaign.scheduledStart = getUtcISOFromMoment(campaignLocal.startDateTimezone);
        }
      }

      if(campaignLocal.startDateTimezone.minutes() > campaignLocal.endDateTimezone.minutes()) {
        campaignLocal.endDateTimezone.minutes(campaignLocal.startDateTimezone.minutes());
        campaign.scheduledEnd = getUtcISOFromMoment(campaignLocal.endDateTimezone);
      }

      if(campaignLocal.startDateTimezone.minutes() < campaignLocal.endDateTimezone.minutes()) {
        campaignLocal.startDateTimezone.minutes(campaignLocal.endDateTimezone.minutes());
        campaign.scheduledStart = getUtcISOFromMoment(campaignLocal.startDateTimezone);
      }

      campaignLocal.startDateTimezone.local();
      campaignLocal.endDateTimezone.local();
    }
  } //end: alignDates()

  setResultsDateParams(report, campaign, campaignLocal) {
    if(campaign.scheduledStart && campaign.scheduledEnd) {
      this.setDates(campaign, campaignLocal);
    }
    else {
      //Eloqua-Controlled campaigns don't have a scheduledStart.
      var firstDate = report[0].resultDateTime;
      var scheduledStart = new Date(firstDate.substr(0, 4), firstDate.substr(5, 2) - 1, firstDate.substr(8, 2));
      scheduledStart.setHours(0, 0, 0, 0);

      var lastDate = report[report.length - 1].resultDateTime;
      var scheduledEnd = new Date(lastDate.substr(0, 4), lastDate.substr(5, 2) - 1, lastDate.substr(8, 2));
      scheduledEnd.setHours(0, 0, 0, 0);

      //If dates are reversed...
      if(scheduledEnd.getTime() < scheduledStart.getTime()) {
        var tmpDate = scheduledStart;
        scheduledStart = scheduledEnd;
        scheduledEnd = tmpDate;
      }

      campaignLocal.currentDay = daysBetweenDates(scheduledStart, campaignLocal.currentDate);
      campaignLocal.lengthDays = daysBetweenDates(scheduledStart, scheduledEnd);
    }
  }

  lateStartDate(startDate, currentDate, timezone) {
    if(
      startDate
      && startDate.isSame(currentDate, 'day')
      && moment()
        .tz(timezone)
        .hour() >= 17
    ) {
      return true;
    }
    return false;
  }

  updateEndDate(campaign, campaignLocal) {
    //Set time (hours & minutes) to current timezone time.
    setToTimezoneTime(campaignLocal.scheduledEndLocal, campaign.timezone);

    //Update moment date from the local equivalent (which is in the UI date control)
    campaignLocal.endDateTimezone = getMomentFromLocalEquivalent(campaignLocal.scheduledEndLocal, campaign.timezone);

    //Update the ISO string in campaign.scheduledEnd
    campaign.scheduledEnd = getUtcISOFromMoment(campaignLocal.endDateTimezone);

    this.updateCampaignLength(campaign, campaignLocal);
  }

  //Convert the selected send times to a 24x7 matrix - call before save. (was: convertUiParamsToMatrix)
  convertLocalToSendTimesMatrix(campaignLocal) {
    var matrix = [];
    var days = [
      campaignLocal.restrictSendTimes.daysOfTheWeek.sunday,
      campaignLocal.restrictSendTimes.daysOfTheWeek.monday,
      campaignLocal.restrictSendTimes.daysOfTheWeek.tuesday,
      campaignLocal.restrictSendTimes.daysOfTheWeek.wednesday,
      campaignLocal.restrictSendTimes.daysOfTheWeek.thursday,
      campaignLocal.restrictSendTimes.daysOfTheWeek.friday,
      campaignLocal.restrictSendTimes.daysOfTheWeek.saturday
    ];

    var startHour = campaignLocal.restrictSendTimes.startTime.getHours();
    var endHour = campaignLocal.restrictSendTimes.endTime.getHours();

    for(var day = 0; day < 7; day++) {
      var hoursArray = [];
      for(var hour = 0; hour < 24; hour++) {
        hoursArray[hour] = false;

        //if we aren't restricting send times, set everything to true.
        if(!campaignLocal.restrictSendTimes.active) {
          hoursArray[hour] = true;
        }
        else if(days[day]) {
          if(hour >= startHour && hour <= endHour) {
            hoursArray[hour] = true;
          }
        }
      }
      matrix[day] = hoursArray;
    }

    return matrix;
  }

  //Pass in campaign.sendTimeConstraints matrix and set campaignLocal needed for the screen.
  convertSendTimesMatrixToLocal(campaign, campaignLocal) {
    if(typeof campaign.sendTimeConstraints !== 'undefined') {
      var days = [
        false, //sunday
        false, //monday
        false, //tuesday
        false, //wednesday
        false, //thursday
        false, //friday
        false //saturday
      ];

      var startTimeSet = false;
      var endTimeSet = false;

      //Set a single startHour and endHour and check every day for the booleans.
      campaign.sendTimeConstraints.forEach((dayArray, i) => {
        //Find startTime and check day
        for(var j = 0; j < dayArray.length; j++) {
          if(dayArray[j]) {
            days[i] = true;

            if(!startTimeSet) {
              campaignLocal.restrictSendTimes.startHour = j;
              startTimeSet = true;
            }
            break;
          }
        }

        //Find endTime
        if(!endTimeSet) {
          for(var k = dayArray.length - 1; k >= 0; k--) {
            if(dayArray[k]) {
              campaignLocal.restrictSendTimes.endHour = k;
              endTimeSet = true;
              break;
            }
          }
        }
      });

      campaignLocal.restrictSendTimes.daysOfTheWeek.sunday = days[0];
      campaignLocal.restrictSendTimes.daysOfTheWeek.monday = days[1];
      campaignLocal.restrictSendTimes.daysOfTheWeek.tuesday = days[2];
      campaignLocal.restrictSendTimes.daysOfTheWeek.wednesday = days[3];
      campaignLocal.restrictSendTimes.daysOfTheWeek.thursday = days[4];
      campaignLocal.restrictSendTimes.daysOfTheWeek.friday = days[5];
      campaignLocal.restrictSendTimes.daysOfTheWeek.saturday = days[6];

      //If everything is true, set restrictSendTimes.active to false -- and default the UI.
      if(campaignLocal.restrictSendTimes.startHour === 0 && campaignLocal.restrictSendTimes.endHour === 23 && days[0] && days[1] && days[2] && days[3] && days[4] && days[5] && days[6]) {
        //This turns off send time restrictions.
        campaignLocal.restrictSendTimes.active = false;
      }
      else {
        campaignLocal.restrictSendTimes.active = true;

        // If restrictSendTimes are the same as recommended, set letMotivaDecide to true
        if(campaign.letMotivaDecideSendTimeWindow) {
          campaignLocal.restrictSendTimes.show = false;
        }
      }
    }
  }

  restrictSendTimesMsg(restrictSendTimes, timezone) {
    var msg = '';
    var days = [];

    if(restrictSendTimes.daysOfTheWeek.sunday) days.push('Sunday');
    if(restrictSendTimes.daysOfTheWeek.monday) days.push('Monday');
    if(restrictSendTimes.daysOfTheWeek.tuesday) days.push('Tuesday');
    if(restrictSendTimes.daysOfTheWeek.wednesday) days.push('Wednesday');
    if(restrictSendTimes.daysOfTheWeek.thursday) days.push('Thursday');
    if(restrictSendTimes.daysOfTheWeek.friday) days.push('Friday');
    if(restrictSendTimes.daysOfTheWeek.saturday) days.push('Saturday');

    if(days.length > 0) {
      msg = `Emails can be sent ${this.getSendDaysMessage(restrictSendTimes)}`;

      if(restrictSendTimes.startTime && restrictSendTimes.endTime) {
        msg += ` between ${this.datePipe.transform(restrictSendTimes.startTime, 'h')}:00 ${this.datePipe.transform(restrictSendTimes.startTime, 'a')} and ${this.datePipe.transform(
          restrictSendTimes.endTime,
          'h'
        )}:59 ${this.datePipe.transform(restrictSendTimes.endTime, 'a')}`;

        if(timezone) {
          msg += ` ${timezone} time.`;
        }
        else {
          msg += '.';
        }
      }
      else {
        msg += '.';
      }
    }
    else {
      msg = 'Please select at least one day to send emails.';
    }

    return msg;
  } //end: restrictSendTimesMsg()

  getSendDaysMessage(restrictSendTimes) {
    var days = [];
    var sendDaysList = '';

    var weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    weekDays.forEach(day => {
      if(restrictSendTimes.daysOfTheWeek[day.toLowerCase()]) days.push(day);
    });

    var isGap = false;
    var curDate = 0;
    weekDays.forEach(day => {
      if(day !== days[curDate] && curDate > 0 && curDate < days.length) isGap = true;
      if(day === days[curDate]) curDate++;
    });

    if(days.length === 2) {
      sendDaysList = `${days[0]} and ${days[1]}`;
    }
    else if(days.length > 0 && isGap) {
      sendDaysList = [days.slice(0, -1).join(', '), days.slice(-1)[0]].join(days.length < 2 ? '' : ', and ');
    }
    else {
      sendDaysList = `${days[0]} to ${days[days.length - 1]}`;
    }

    return sendDaysList;
  } //end: getSendDaysMessage()
}
